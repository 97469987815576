import ConfigService from '../config/config.json';
import axios from 'axios';

//Send Mail Recovery
export async function sendMailRecovery(lang, userData) {
  return await axios.post(`${ConfigService.service_url}/login/passwordForgot/${lang}`, userData);
}

//Recovery Password Check
export async function recoveryPasswordCheck(guid) {
  return await axios.get(`${ConfigService.service_url}/recovery?GUID=${guid}`);
}

//Recovery Password
export async function resetPassword(password, guid) {
  return await axios.post(`${ConfigService.service_url}/login/resetpassword?GUID=${guid}`, { password: password});
}