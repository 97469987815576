import ConfigService from '../config/config.json';
import axios from 'axios';

//Get user list
export async function getUsers(search, query, page, items_per_page) {
  return await axios.post(`${ConfigService.service_url}/admin/get/users/${query}/${page}/${items_per_page}`, { search : search });
}
//Get users role list
export async function getUsersRoles(search, page, items_per_page) {
  return await axios.post(`${ConfigService.service_url}/admin/get/users/roles/${page}/${items_per_page}`, { search : search });
}

//Get rooms list
export async function getRooms(search, page, items_per_page) {
  return await axios.post(`${ConfigService.service_url}/admin/get/rooms/${page}/${items_per_page}`, { search : search });
}

//Get rooms with payment wait
export async function getRoomsWithPaymentWait(search, page, items_per_page) {
  return await axios.post(`${ConfigService.service_url}/admin/get/roomspw/${page}/${items_per_page}`, { search : search });
}

//Set rooms leader
export async function setRoomsLeader(room_id, user_id) {
  return await axios.get(`${ConfigService.service_url}/admin/set/rooms/leader/${room_id}/${user_id}`);
}

//Lock/Unlock user
export async function setLockUser(user_id, isLocked) {
  return await axios.get(`${ConfigService.service_url}/admin/lock/user/${user_id}/${isLocked}`);
}

//Add BOT
export async function addRoomsBot(room_id, botData) {
  return await axios.post(`${ConfigService.service_url}/admin/add/rooms/bot/${room_id}`, botData);
}

//Check username
export async function checkUsernameExistence(username) {
  return await axios.get(`${ConfigService.service_url}/checkUsernameExistence/${username}`,);
}

//Get queue list
export async function getQueueList(search, page, items_per_page) {
  return await axios.post(`${ConfigService.service_url}/admin/get/queue/${page}/${items_per_page}`, { search : search });
}