import React, { useEffect, useState, useRef } from 'react';
import './explanations.css';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';


function Explanations() {
  const { t } = useTranslation();
  const lang= localStorage.getItem('language')


  useEffect(() => {
      i18n.changeLanguage(lang); 
    }, [lang]);
  const hc = useRef(null);
  const cards = Array.from({ length: 8 }, () => React.createRef());
  let currentCard;


  useEffect(() => {
    hc.current.addEventListener('scroll', calc_isInViewport);
    return () => {
      if (hc.current) {
        hc.current.removeEventListener('scroll', calc_isInViewport);
      }
    };
  }, []);

  function calc_isInViewport() {
    for (let index = 0; index < cards.length; index++) {
      const element = cards[index];
      if (isInViewport(element.current)) {
        currentCard = element.current;
        changeBackground(currentCard);
      }
    }
  }

  function changeBackground(element) {
    let color = '';
    if (element) {
      color = element.classList[1];
    }
    if (color) {
      hc.current.className = 'pdf-container';
      hc.current.className = 'pdf-container scroll-area-pdf' + color;
    }

    console.log(element);
  }

  function isInViewport(el) {
    if (el) {
      const rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }
  }

  return (
    <div ref={hc} className="pdf-container">
      {Array.from({ length: 8 }, (_, index) => (
        <div key={index} ref={cards[index]} className={`scroll-area-pdf ${index + 1}`}>
          <div className="card_pdf">
            <div className="card-container-pdf">
              <img src={`/img/Spiegazione${t('spiegazione')}/Diapositiva${index + 1}.PNG`} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Explanations;
