import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import i18n, { t } from 'i18next';
import { MDBContainer, MDBBtn, MDBCard, MDBTypography } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ConfigService from '../config/config.json';
import Modal from 'react-modal';

import '../home/home.css';
import './registrazione.css';

function Registrazione() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [secret_code, setSecretCode] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //Error message
  const [showerror_message, setShowerror_message] = useState('');

  const lang = localStorage.getItem('language')
  const [showConfirmCodeUI, setShowConfirmCodeUI] = useState(false);
  const [res, setRes] = useState(Boolean);
  const [guid, setGuid] = useState('');
  const navigate = useNavigate();
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const UpperCaseChars = /[`A-Z]/;
  const [passwdVisibile, setpasswdVisibile] = useState(false)

  const tooglePasswdVisibile = () => {
    setpasswdVisibile((prevVisibile) => (!prevVisibile))
  }

  const handleRegistrazione = async () => {
    try {

      if (!agreeTerms) {
        setShowerror_message(t('agree_terms'));
        return;
      }

      
      if(!username || username.length < 2){
        setShowerror_message(t("no.username"));
        return;
      }
      if(!email){
        setShowerror_message(t("no.email"));
        return;
      }
      if(!password || password.length < 8 || !specialChars.test(password) || !UpperCaseChars.test(password)){
        setShowerror_message(t("no.password"));
        return;
      }
      setShowerror_message('');
      if(!username || !email || !password) return;
      axios.post(`${ConfigService.service_url}/register/request/` + i18n.language, {
        username: username,
        password: password,
        email: email,
      })
      .then((res) => {
        if(res.status === 200){
          if(res.data) {
            setGuid(res.data);
            setShowConfirmCodeUI(true);
          }
        }
      })
      .catch((error) => {
        try {
          if (error && error.response.status === 400) {
            setShowerror_message(t(error.response.data));
          } else {
            throw error;
          }
        } catch (error) {
          setShowerror_message(t('generic_error'));
        }
      });
    } catch (error) {
      setShowerror_message(t('generic_error'));
    }
  };

  const sendCode = async () => {
    if(!secret_code){
      setShowerror_message(t('wrong.code'));
      return;
    }
    try{
      const response = await axios.post(`${ConfigService.service_url}/register/confirmation/${guid}`, {
        secret_code: secret_code,
      });

      if (response.status === 200) {
        setShowConfirmCodeUI(false);
        navigate('/login');
      }
    } catch(error) {
      setShowerror_message(t('wrong.code'));
    }
  };

  const checkExistingUser = async () => {
    try {
      const response = await axios.get(`${ConfigService.service_url}/checkUsernameExistence/${username}`);

      if (response.data === true) {
        setShowerror_message(t('username.used'));
        setRes(true);
      } else {
        setShowerror_message('');
        setRes(false);
      }
    } catch (error) {
      console.error("Errore durante il controllo dell'username:", error);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(lang); 
  }, [lang]);

  return (
    <div>
      {!showConfirmCodeUI ? 
      <MDBContainer>
        <MDBCard>
          <MDBTypography className="card-title" tag="h5">
            {t('register')}
          </MDBTypography>
          <MDBTypography className="card-text-error" tag="p">
            {showerror_message}
          </MDBTypography>
          <div className="form__container">
            <div className="form__input">
              <div>
                <FontAwesomeIcon icon={faUser} />
              </div>
              <div>
                <input className="form__field" type="text" placeholder="Username" id="i_username" value={username} onChange={(e) => setUsername(e.target.value)} onBlur={checkExistingUser} />
                <label htmlFor="i_username" className="form__label">
                  Username
                </label>
              </div>
            </div>
            <div className="form__input">
              <div>
                <FontAwesomeIcon icon={faKey} />
              </div>
              <div>
                <input className="form__field" type="e-mail" placeholder="E-Mail" id="i_email" value={email} onChange={(e) => { setEmail(e.target.value); }} />
                <label htmlFor="i_email" className="form__label">
                  E-Mail
                </label>
              </div>
            </div>
            <div className="form__input">
              <div>
                <FontAwesomeIcon icon={faKey} />
              </div>
              <div>
              <i onClick={tooglePasswdVisibile} style={{ position:"absolute", cursor: 'pointer', right: '0px', color: '#5315d0'}} >
                      {passwdVisibile ?   <FontAwesomeIcon icon={faEye} /> :  <FontAwesomeIcon icon={faEyeSlash} />}
                        </i>
                      <input minLength={4} maxLength={32} className="form__field" type={passwdVisibile ? "text" : "Password"} placeholder="Password" id="i_password" value={password} onChange={(e) => setPassword(e.target.value)} />
      
                <label htmlFor="i_password" className="form__label">
                  Password
                </label>
              </div>
            </div>
           <div>
            
           </div>
            <div className="form__input">
              <button onClick={handleRegistrazione} disabled={res}>
              {t('register')}
              </button>
            </div>
            <div className="form__input">
  <div>
    <input
      type="checkbox"
      id="i_agree_terms"
      checked={agreeTerms}
      onChange={() => setAgreeTerms(!agreeTerms)}
    />
  </div>
  <div>
    <label style={{fontSize: '15px'}} >
  {t('terms')}
    </label>
  
  </div>
</div>
            
          </div>
          <Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  ariaHideApp={false}
  contentLabel="Termini d'uso">
  <h2>{t('terms_and_conditions')}</h2>
 
</Modal>
          
        </MDBCard>
      </MDBContainer> :
      <MDBContainer>
        <MDBCard>
          <MDBTypography className="card-title" tag="h5">
            {t('cofirmregistrationcode')}
          </MDBTypography>
          <MDBTypography className="card-text-error" tag="p">
            {showerror_message}
          </MDBTypography>
          <MDBTypography style={{textAlign: 'center'}} tag="p">
          {t('cofirmregistrationcode_message')}
          </MDBTypography>
          <div className="form__container">
            <div className="form__input">
              <div>
                <FontAwesomeIcon icon={faKey} />
              </div>
              <div>
                <input
                  className="form__field"
                  id="i_secret_code"
                  type="text"
                  value={secret_code}
                  onChange={(e) => {
                    setSecretCode(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="form__input">
              <button onClick={sendCode}>
              {t('register')}
              </button>
            </div>
          </div>
        </MDBCard>
      </MDBContainer>
      }
    </div>
  );
}

export default Registrazione;
