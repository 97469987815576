import {React, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

 function AboutUS () {
    const { t } = useTranslation();
    const lang= localStorage.getItem('language')


    useEffect(() => {
        i18n.changeLanguage(lang); 
      }, [lang]);

      return(
        <div className='home-container' style={{textAlign: 'center', justifyContent:'center'}}>
            <h6>{t('descrizione')}</h6>
        </div>
      )

}

export default AboutUS;