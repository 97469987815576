import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { MDBContainer, MDBCard, MDBTypography } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser, faEye, faEyeDropper, faEyeLowVision, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ConfigService from '../config/config.json';
import '../home/home.css';
import './registrazione.css';

function Login() {
  //Translation
  const { t } = useTranslation();
  //Input
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwdVisibile, setpasswdVisibile] = useState(false)
  const  [passwdModal, setPasswdModal] = useState(false)
  //Error message
  const [showerror_message, setShowerror_message] = useState('');
  //Rooting
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    if(localStorage.getItem('user')){
      navigate("/");
    }
  }, []);

  const tooglePasswdVisibile = () => {
    setpasswdVisibile((prevVisibile) => (!prevVisibile))
  }

  const showPasswdModal = () => {
    setPasswdModal(true)
  }


  const loginHandler = async () => {
    try {
      if (!username && !password) return;
      axios
        .post(`${ConfigService.service_url}/login`, {
          username: username,
          password: password,
        })
        .then( (res)  => {
             if (res.status === 200 || res.status === 202) {
               if (res.status === 200 && res.data) {
                 let user = res.data;
                 user.token = res.headers['authorization'].split(' ')[1];
                 localStorage.setItem('user', JSON.stringify(user));
                 navigate('/');
               } else if (res.status === 202) {
                 const tokenSpecial = res.headers['authorization'].split(' ')[1];
                 localStorage.setItem('tokenSpecial', JSON.stringify(tokenSpecial));
                 navigate('/regData');
               }
             }
        })
        .catch((error) => {
          console.log(error);
          try {
            if (error && error.response.status === 400) {
              if(error.response.data === 'user.banned'){
                setShowerror_message(t('user.banned'))
              }
              else{
                setShowerror_message(t(error.response.data));

              }
            } else {
              throw error;
            }
          } catch (error) {
            setShowerror_message(t('generic_error'));
          }
        });
    } catch (error) {
      setShowerror_message(t('generic_error'));
    }
  };

  return (
    <div>
        <div>
          <MDBContainer>
            <MDBCard>
              <MDBTypography className="card-title" tag="h5">
                {t('login')}
              </MDBTypography>
              <MDBTypography className="card-text-error" tag="p">
                {showerror_message}
              </MDBTypography>
                <div className="form__container">
                  <div className="form__input">
                    <div>
                      <FontAwesomeIcon icon={faUser} />
                      &nbsp;
                    </div>
                    <div>
                      <input className="form__field" type="text" placeholder="Username" id="i_username" value={username} onChange={(e) => setUsername(e.target.value)} />
                      <label htmlFor="i_username" className="form__label">
                        Username
                      </label>
                    </div>
                  </div>
                  <div className="form__input">
                    <div>
                      <FontAwesomeIcon icon={faKey} />
                      &nbsp;
                    </div>
                    <div>
                    <i onClick={tooglePasswdVisibile} style={{ position:"absolute", cursor: 'pointer', right: '0px', color: '#5315d0'}} >
                      {passwdVisibile ?   <FontAwesomeIcon icon={faEye} /> :  <FontAwesomeIcon icon={faEyeSlash} />}
                        </i>
                      <input minLength={4} maxLength={32} className="form__field" type={passwdVisibile ? "text" : "Password"} placeholder="Password" id="i_password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    
                      <label htmlFor="i_password" className="form__label">
                        Password
                      </label>
                    </div>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <span>{t('notamember')} <a onClick={() => {navigate("/signUp")}}> {t('register')} </a> </span>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <span><a onClick={() => {navigate("/passwordDimenticata")}}> {t('forgotpassword')} </a></span>
                  </div>
                  <div className="form__input">
                    <button onClick={loginHandler}>{t('login')}</button>
                  </div>
              </div>
            </MDBCard>
          </MDBContainer>
        </div>

        <Modal show={passwdModal}>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Secret Code</Form.Label>
              <Form.Control type="text"  autoFocus />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
