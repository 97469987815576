import ConfigService from '../config/config.json';
import axios from 'axios';

//Get board list
export async function getAllRooms() {
  return await axios.get(`${ConfigService.service_url}/rooms`);
}

//Get board queue list
export async function getAllQueueRooms() {
  return await axios.get(`${ConfigService.service_url}/rooms/queue`);
}