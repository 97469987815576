import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBCardBody, MDBTypography, MDBCardText, MDBRow, MDBCol, MDBCard, MDBModal, MDBModalDialog, MDBModalContent, MDBModalBody, MDBModalFooter,MDBBtn} from 'mdb-react-ui-kit';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ConfigService from '../config/config.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser, faEye, faEyeDropper, faEyeLowVision, faEyeSlash, faPhone, faWallet } from '@fortawesome/free-solid-svg-icons';
import './style.css'
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

function Profile() {
  const navigate = useNavigate();
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(storedUser || null);
  const { t } = useTranslation();
  const lang= localStorage.getItem('language');
  const [modalDelete, setModalDelete] = useState(Boolean);
  const [editor, setEditor] = useState(Boolean);
  const [nameInput, setNameInput] = useState(user?.name || '');
  const [usernameInput, setUsernameInput] = useState(user?.username || '');
  const [phoneInput, setPhoneInput] = useState(user?.phone || '');
  const [telegramInput, setTelegramInput] = useState(user?.telegram || '');
  const [walletInput, setWalletInput] = useState(user?.wallet_address || '');
  const [error, setError] = useState(false)


  const setModal = () => {
    setModalDelete(true)
  }

const editInput = () => {
  setEditor(true)
}

const saveChanges = async () => {
  if (
    // nameInput.trim() === "" ||
    // usernameInput.trim() === "" ||
    // phoneInput.trim() === "" ||
    telegramInput.trim() === "" 
    // walletInput.trim() === ""
  ) {
    setError(true);
    return;
  } else {

    let newUser = {
      name: nameInput,
      username: usernameInput,
      phone: phoneInput,
      telegram: telegramInput,
      wallet_address: walletInput
    };
    await axios.post(`${ConfigService.service_url}/user/changeaccountdata`, newUser);
    let oldUser = JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('user', JSON.stringify({ ...oldUser, ...newUser }));
    setUser((prevUser) => ({
      ...prevUser,
      name: nameInput,
      username: usernameInput,
      phone: phoneInput,
      telegram: telegramInput,
      wallet_address: walletInput,
    }));
    setEditor(false);
  }
};


  const deleteOfficialAccount = async () => {
      await axios.delete(`${ConfigService.service_url}/user/deleteaccount`)
      localStorage.removeItem("user");
      setModalDelete(false);
      navigate('/');
  }

  useEffect(() => {
      i18n.changeLanguage(lang); 
    }, [lang]);

  return (
    <div>
      {user && (
        <div>
          {user && (
            <div>
              <MDBContainer>
                <MDBCard>
                <MDBTypography className="card-title" tag="h5">{t('user.profile')}</MDBTypography>
                  <MDBCardBody className='profile'>
                    <MDBRow className="pt-1">
                      <MDBCol className="mb-3 text-center">
                      {editor ? (
                        <>
                        {/* <div style={{ margin: '20px' }} >
                        <input style={{borderRadius:'5px', padding:'5px'}} className={error && nameInput.trim() === "" ? 'error' : ''} placeholder='Name' type="text" value={nameInput} onChange={(e) => setNameInput(e.target.value)} />
                          <label style={{marginLeft:'20px'}} >
                            {t('profile.name')}
                            </label>
                        </div> */}
                        {/* <div style={{margin:'20px'}}>
                          <input style={{borderRadius:'5px', padding:'5px'}} className={error && phoneInput.trim() === "" ? 'error' : ''} type="text" placeholder='Phone'  value={phoneInput} onChange={(e) => setPhoneInput(e.target.value)} />
                          <label style={{marginLeft:'20px'}} >
                          {t('profile.phone')}
                            </label>

                        </div> */}
                        <div style={{margin:'20px'}}>
                          <input style={{borderRadius:'5px', padding:'5px'}} className={error && telegramInput.trim() === "" ? 'error' : ''} placeholder='Telegram' type="text" value={telegramInput} onChange={(e) => setTelegramInput(e.target.value)} />
                          <label style={{marginLeft:'20px'}} >
                          {t('profile.telegram')}
                            </label>
                        </div>
                        {/* <div style={{margin:'20px'}}>
                          <input style={{borderRadius:'5px', padding:'5px'}} className={error && walletInput.trim() === "" ? 'error' : ''} type="text" placeholder='Wallet' value={walletInput} onChange={(e) => setWalletInput(e.target.value)} />
                          <label style={{marginLeft:'20px'}} >
                          {t('profile.wallet')} 
                            </label>
                        </div> */}
                        <hr className="mt-1 mb-1" />
                          <button onClick={saveChanges}>{t('button.save')}</button>
                          <button onClick={() => setEditor(false)}>{t('button.cancel')}</button>
                        </>
                      ) : (
                        <>
                         <div className="form__container">
                          <div className="form__input">
                            <div>
                              <MDBTypography tag="span"><b>{t('profile.username')}:</b>&nbsp;{user.username}</MDBTypography>
                            </div>
                          </div>
                          {/* <div className="form__input">
                            <div>
                              <FontAwesomeIcon icon={faUser} />&nbsp;
                              <MDBTypography tag="span"><b>{t('profile.name')}:</b>&nbsp;{user.username}</MDBTypography>
                            </div>
                          </div> */}
                          {/* <div className="form__input">
                            <div>
                              <FontAwesomeIcon icon={faPhone} />&nbsp;
                              <MDBTypography tag="span"><b>{t('profile.phone')}:</b>&nbsp;{user.phone}</MDBTypography>
                            </div>
                          </div> */}
                          <div className="form__input">
                            <div>
                              <FontAwesomeIcon icon={faTelegram} />&nbsp;
                              <MDBTypography tag="span"><b>{t('profile.telegram')}:</b>&nbsp;{user.telegram}</MDBTypography>
                            </div>
                          </div>
                          {/* <div className="form__input">
                            <div>
                              <FontAwesomeIcon icon={faWallet} />&nbsp;
                              <MDBTypography tag="span"><b>{t('profile.wallet')}:</b>&nbsp;{user.wallet_address}</MDBTypography>
                            </div>
                          </div> */}
                        </div>
                        <hr className="mt-1 mb-1" />
                          <button style={{
                        padding: "5px",
                          backgroundColor: "red",
                        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)"
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
                          e.target.style.backgroundColor = "firebrick";
                          }}
                          onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "red";
                          e.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
                          }}  onClick={setModal}>{t('profile.delete')}</button>
                          <button  style={{
                        padding: "5px",
                        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                        onMouseEnter={(e) => {
                        e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
                        }}
                        onMouseLeave={(e) => {
                        e.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
                        }} onClick={editInput}>{t('profile.modify')}</button>
                        </>
                      )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                  
                </MDBCard>
              </MDBContainer>
            </div>
          )}
          <MDBModal show={modalDelete} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalBody>
              <p>{t('profile.delete.prompt')}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <button color="secondary" onClick={() => setModalDelete(false)}>{t('button.cancel')}</button>
              <button color="danger" onClick={deleteOfficialAccount}>{t('button.save')}</button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
        </div>
      )}

    </div>
  );
}

export default Profile;
