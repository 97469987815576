import { React, useEffect, useState } from 'react';
import { MDBContainer, MDBBtn, MDBCard, MDBTypography } from 'mdb-react-ui-kit';
import i18n, { t } from 'i18next';
import axios from 'axios';
import ConfigService from '../config/config.json';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendMailRecovery, recoveryPasswordCheck, resetPassword} from './passwdForget.provider';

function PasswordForget() {
    const [email, setEmail] = useState("")
    const [showerror_message, setShowerror_message] = useState('');
    const [resetPasswordStep, setResetPasswordStep] = useState(0)
    const [passwdProvvisoria, setPasswdProvvisoria] = useState("")
    const [nuovaPassword, setNuovaPassword] = useState("")
    const [verificaPassword, setVerificaPassword] = useState("");
    const [guidPasswordReset, setGuidPasswordReset] = useState("");
    const navigate = useNavigate()
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const UpperCaseChars = /[`A-Z]/;

    const location = useLocation();

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const guid = searchParams.get('GUID');

      if(guid){
        call_recoveryPasswordCheck(guid);
      }
      // Now 'guid' contains the value of the GUID parameter
      console.log(guid);
    }, [location.search]);

    const sendPassProvvisoria = async () => {
        if(!email){
          setShowerror_message(t('no.email'));
          return;
        }
        //! Chiamata BackEnd Per inviare Password Provvisoria
    }

      const OkPassProvvisoria = async () => {
        //! Chiamata BackEnd Per verificare che la passWord provvisoria sia giusta
        setResetPasswordStep(false)
      }

      const changePassword = async () => {
          if(!nuovaPassword || nuovaPassword.length < 8 || !specialChars.test(nuovaPassword) || !UpperCaseChars.test(nuovaPassword)){
              setShowerror_message(t("no.password"));
              return;
            }
            if(nuovaPassword !== verificaPassword) {
                setShowerror_message("Password Non Uguali")
                return;
            }

        //!Chiamata BackEnd Reset Password
        setResetPasswordStep(0);


        /*setResetPassword(0)
        setShowerror_message("")*/


        

      }

      async function call_sendMailRecovery(e) {
        try {
          e.preventDefault();
          const response = await sendMailRecovery(i18n.language, { "email" : email});
        } catch (error) {
          //debugger;
          console.error('Error fetching users:', error);
        }
      }

      async function call_resetPassword(e) {
        try {
          e.preventDefault();
          const response = await resetPassword(nuovaPassword, guidPasswordReset);
          if(response.status===200){
            navigate('/login');
          }
        } catch (error) {
          //debugger;
          console.error('Error fetching users:', error);
        }
      }

      async function call_recoveryPasswordCheck(guid) {
        try {
          const response = await recoveryPasswordCheck(guid);
          if(response.status===200){
            setResetPasswordStep(2);
            setGuidPasswordReset(guid);
          }
        } catch (error) {
          //debugger;
          console.error('Error fetching users:', error);
        }
      }

    return (

        <div>
 {
  resetPasswordStep === 0 ? (
    <MDBContainer>
      <MDBCard>
        <MDBTypography className="card-title" tag="h5">
          {t('recuperaPassword')}
        </MDBTypography>
        <MDBTypography className="card-text-error" tag="p">
          {showerror_message}
        </MDBTypography>
        <div className="form__container">
          <div className="form__input">
            <div>
              <input
                className="form__field"
                type="e-mail"
                placeholder="E-Mail"
                id="i_email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <label style={{ marginLeft: '125px' }}>E-Mail</label>
            </div>
          </div>
          <button onClick={(e) => {setResetPasswordStep(1); call_sendMailRecovery(e);}}>Send Code</button>
        </div>
      </MDBCard>
    </MDBContainer>
  ) : resetPasswordStep === 1 ? (
    <MDBContainer>
      <MDBCard>
        <MDBTypography className="card-title" tag="h5">
          {t('PasswordProvvisoria')}
        </MDBTypography>
        <MDBTypography className="card-text-error" tag="p">
          {showerror_message}
        </MDBTypography>
        <div className="form__container">
          <div className="form__input" style={{width: '100%'}}>
            <div>
              {/*<input
                className="form__field"
                type="text"
                placeholder="Password Provvisoria"
                id="i_email"
                value={passwdProvvisoria}
                onChange={(e) => {
                  setPasswdProvvisoria(e.target.value);
                }}
              />*/}
              <p style={{margin: '0px 20px', marginBottom: '20px'}}>{t('mailRecoveryPasswordSent')}</p>
            </div>
          </div>
          {/*<button onClick={() => setResetPassword(2)}>Send</button>*/}
        </div>
      </MDBCard>
    </MDBContainer>
  ) : resetPasswordStep === 2 ? (
    <MDBContainer>
      <MDBCard>
        <MDBTypography className="card-title" tag="h5">
          {t('recuperaPassword')}
        </MDBTypography>
        <MDBTypography className="card-text-error" tag="p">
          {showerror_message}
        </MDBTypography>
        <div className="form__container">
          <div className="form__input">
            <div>
              <input
                className="form__field"
                type="password"
                placeholder={t('nuovaPassword')}
                value={nuovaPassword}
                onChange={(e) => {
                  setNuovaPassword(e.target.value);
                }}
              />
              <label style={{ marginLeft: '85px' }}>{t('nuovaPassword')}</label>
            </div>
          </div>
          <div className="form__input">
            <div>
              <input
                className="form__field"
                type="password"
                placeholder={t('VerificanuovaPassword')}
                value={verificaPassword}
                onChange={(e) => {
                  setVerificaPassword(e.target.value);
                }}
              />
              <label style={{ marginLeft: '60px' }}>{t('verificaNuovaPassword')}</label>
            </div>
          </div>
          <button onClick={call_resetPassword}>Send</button>
        </div>
      </MDBCard>
    </MDBContainer>
  ) : null
}
        </div>
    )
}

export default PasswordForget;