import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import './navbar.css';
import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFilePdf, faHouse, faRainbow, faRightToBracket, faVideo, faPeopleGroup, faDonate, faContactCard, faDashboard, faGlobe, faUser, faTable, faBars } from '@fortawesome/free-solid-svg-icons';


function NavbarComponent() {
  const { t } = useTranslation();
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const storedLanguage = localStorage.getItem('language');
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage || 'en')
  const [user, setUser] = useState(storedUser || null);
  const navigate = useNavigate();
  const lang= localStorage.getItem('language');

  const [isMenuBarOpen, setMenuBarOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang); 
  }, [lang]);

  const changeLanguage = (lang) => {
    setSelectedLanguage(lang);
    localStorage.setItem('language', lang);
    navigate("/");
  }

  const handleLogout = () => {
    logout();
    setMenuBarOpen(false)
    navigate('/');
  };

  function isLoggedSomehow(){
    if(localStorage.getItem('user')){
      if(JSON.parse(localStorage.getItem('user'))){
        return true;
      }
    }
    return false;
  }

  function isLogged(){
    if(localStorage.getItem('user')){
      if(JSON.parse(localStorage.getItem('user'))?.telegram){
        return true;
      }
    }
    return false;
  }

  function isAdmin(){
    if(localStorage.getItem('user')){
      if(JSON.parse(localStorage.getItem('user'))?.isAdmin){
        return true;
      }
    }
    return false;
  }

  function triggerMenu() {
    setMenuBarOpen(!isMenuBarOpen);
  }

  function logout(){
    localStorage.removeItem("user");
    navigate("/login");
  }

  return (
    <>
    <Navbar expand="lg" className="navbar">
      <Container fluid>
        <Navbar.Brand onClick={()=>navigate('/')}>
          <FontAwesomeIcon icon={faRainbow} />
          &nbsp;The Magical Rainbow
        </Navbar.Brand>
        <div onClick={()=>triggerMenu()} className='menuBar'><FontAwesomeIcon icon={faBars} /></div>
        <Navbar.Collapse id="navbarScroll" className={isMenuBarOpen?"mobileMenuOpen":null}>
          <Nav className="hover-underline-animation navbar-links">
            <Nav.Link onClick={()=>{navigate('/');setMenuBarOpen(false)}}>
              <FontAwesomeIcon icon={faHouse} />&nbsp;Home</Nav.Link>
            { isLogged() && isAdmin() ? <Nav.Link onClick={()=>{navigate("/admin");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faDashboard} />&nbsp;Admin Dashboard</Nav.Link> : null }
            <Nav.Link onClick={()=>{navigate('/explanations');setMenuBarOpen(false)} }><FontAwesomeIcon icon={faBook} />&nbsp;{t('spiegazioni')}</Nav.Link>
            <Nav.Link onClick={()=>{navigate("/video");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faVideo} />&nbsp;Video</Nav.Link>
            <Nav.Link onClick={()=>{navigate("/about-us");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faPeopleGroup} />&nbsp;{t('chisiamo')}</Nav.Link>
            <Nav.Link onClick={()=>{navigate("/donations");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faDonate} />&nbsp;{t('donazioni')}</Nav.Link>
            <Nav.Link onClick={()=>{navigate("/contacts");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faContactCard} />&nbsp;{t('contatti')}</Nav.Link>
            <Nav.Link onClick={()=>{navigate("/pdf");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faFilePdf} />&nbsp;Pdf</Nav.Link>
            { isLogged() ? <Nav.Link onClick={()=>{navigate("/profile");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faUser} />&nbsp;{t('profilo')}</Nav.Link> : null }
            { isLogged() ? <Nav.Link onClick={()=>{navigate("/boards");setMenuBarOpen(false)}}><FontAwesomeIcon icon={faTable} />&nbsp;{t('tavole')}</Nav.Link> : null }
            { isLoggedSomehow() ? <Nav.Link  onClick={handleLogout}><FontAwesomeIcon icon={faRightToBracket} />&nbsp;{t('esci')}</Nav.Link> : null }
            { !isLoggedSomehow() ? <Nav.Link  onClick={() => {navigate('/login');setMenuBarOpen(false)}}><FontAwesomeIcon icon={faRightToBracket} />&nbsp;{t('login')}</Nav.Link> : null }
            <NavDropdown title={<FontAwesomeIcon icon={faGlobe} />}>
              <NavDropdown.Item style={{ color: '#562c67' }} onClick={() => changeLanguage('it')}>Italiano</NavDropdown.Item>
              <NavDropdown.Item style={{ color: '#562c67' }} onClick={() => changeLanguage('en')}>English</NavDropdown.Item>
              <NavDropdown.Item style={{ color: '#562c67' }} onClick={() => changeLanguage('fr')}>Français</NavDropdown.Item>
              <NavDropdown.Item style={{ color: '#562c67' }} onClick={() => changeLanguage('es')}>Español</NavDropdown.Item>
              <NavDropdown.Item style={{ color: '#562c67' }} onClick={() => changeLanguage('pr')}>Português</NavDropdown.Item>
              <NavDropdown.Item style={{ color: '#562c67' }} onClick={() => changeLanguage('ro')}>Română</NavDropdown.Item>
              <NavDropdown.Item style={{ color: '#562c67' }} onClick={() => changeLanguage('ru')}>Русский</NavDropdown.Item>
            </NavDropdown>
          </Nav>
{/* <div style={{ position:'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'transparent'}} onClick={()=>{setMenuBarOpen(false); console.log("ci sono io")}}></div> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default NavbarComponent;
