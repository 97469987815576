import {React, useEffect, useState } from 'react';
import './home.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { getRegisteredUsersNumber } from './home.provider';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';

function Home() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang= localStorage.getItem('language')
  const location = useLocation();
  const [usersCounter, setUsersCounter] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const guid = searchParams.get('GUID');

    if(guid){
      navigate("/passwordDimenticata?GUID="+guid);
    }
    // Now 'guid' contains the value of the GUID parameter
    console.log(guid);
  }, [location.search]);
  
  const loginButton = () => {
    navigate("/login");
  };
  
  const signUpButton = () => {
    navigate("/signUp");
  };

  const tableButton = () => {
    navigate("/boards")
  }
  
  useEffect(() => {
    i18n.changeLanguage(lang); 
  }, [lang]);


  useEffect(() => {
    call_getUsersCounter();
  }, []);

  async function call_getUsersCounter() {
    try {
      const response = await getRegisteredUsersNumber();
      setUsersCounter(response.data);
    } catch (error) {
      //debugger;
      console.error('Error fetching users counter:', error);
    }
  }

  return (
    <div className="home-container">
      <div><h1>{t('greeting')}</h1></div>
      {!localStorage.getItem("user") ? (
        <div className="form__input">
          <button  onClick={loginButton}>{t('login')}</button>
          <button onClick={signUpButton}>{t('register')}</button>
        </div>
      ) :<div className="form__input"> 
           <button onClick={tableButton}>{t('tavole')}</button> 
           </div>}
           <h6
      style={{
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', 
        animation: 'fadeIn 1s ease-in', 
      }}
    >
      {t('introduzione')}
    </h6>

    <h6 style={{color: 'blueviolet'}}>{t('registered_users_number')}: {usersCounter != 0 ? usersCounter : "..."}</h6>
    </div>
  );


}

export default Home;


