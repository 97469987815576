import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../home/home.css';
import './registrazione.css';
import ConfigService from '../config/config.json';
import { useNavigate } from 'react-router-dom';
import i18n, { t } from 'i18next';

import { MDBCardTitle, MDBContainer, MDBInput, MDBBtn, MDBCard } from 'mdb-react-ui-kit';

function RegistrazioneData() {
  const [name, setName] = useState('');
  const [telegram, setTelegram] = useState('');
  const [phone, setPhone] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const tokenS = JSON.parse(localStorage.getItem('tokenSpecial'));
  const [isTelegramValid, setIsTelegramValid] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   try {
  //     login(user);
  //     console.log(user)
  //   } catch (error) {
  //     navigate('/login');
  //   }
  // }, [[]]);

  const handleRegistrazioneData = async () => {

    if (!telegram) {
      setIsTelegramValid(false);
      return;
    }

    try {
      const response = await axios.post(
        `${ConfigService.service_url}/login/sendUserDetails`,
        {
          name: null,
          phone: null,
          telegram: telegram,
          walletAddress: null,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenS}`,
          },
        },
      );

      const res = response.data
      if (response.status === 200) {
              let user = res;
              user.token =  response.headers['authorization'].split(' ')[1];
              localStorage.setItem('user', JSON.stringify(user));
              localStorage.removeItem('tokenSpecial')
              navigate('/');
      }
    } catch (error) {
      console.error('Errore durante la registrazione:', error);
    }
  };

  return (
    <div>
      <MDBContainer className="p-4 flex-column conteiner">
        <MDBCard className="text-center" style={{ border: 'hidden', height: '500px', width: '800px', background: 'transparent' }}>
          <MDBCardTitle className="mb-5">{t('register')}</MDBCardTitle>
          {/* <MDBInput
            classname="mb-2"
            label="Name"
            id="form1"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          /> */}
          {/* <MDBInput
            classNames="mb-4"
            label="Phone Number"
            id="form1"
            type="text"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          /> */}
          <MDBInput
            classNames="mb-4"
            label="Telegram User"
            id="form2"
            type="text"
            value={telegram}
            onChange={(e) => {
              setTelegram(e.target.value);
              setIsTelegramValid(true);
            }}
            required
          />
          {!isTelegramValid && <div style={{ color: 'red' }}>Il campo Telegram è obbligatorio</div>}
          {/* <MDBInput
            classNames="mb-4"
            label="Wallet Address (BEP20)"
            id="form1"
            type="text"
            value={walletAddress}
            onChange={(e) => {
              setWalletAddress(e.target.value);
            }}
          /> */}
          <button className="mb" onClick={handleRegistrazioneData}>
            Sign Up
          </button>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default RegistrazioneData;
