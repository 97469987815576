import ConfigService from '../config/config.json';
import axios from 'axios';
//Get room by id
export async function getRoomByID(room_id) {
  return await axios.get(`${ConfigService.service_url}/rooms/${room_id}`);
}

//Set user payment by id
export async function setPayment(room_id, usr_id) {
  return await axios.put(`${ConfigService.service_url}/rooms/setPayment/${room_id}/${usr_id}`,'');
}

//Set user payment confirmation by id
export async function setPaymentADMIN(room_id, usr_id) {
  return await axios.put(`${ConfigService.service_url}/rooms/setPaymentADMIN/${room_id}/${usr_id}`,'');
}