import axios from 'axios';
import { useNavigate } from 'react-router-dom';



axios.interceptors.request.use(
  function (config) {
    if(localStorage.getItem('user')){
        config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('user')).token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401 || error.response.status == 404) {
        localStorage.removeItem("user");
        window.location.href = "/";
    }
    return Promise.reject(error);
  },
);
