import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getRoomByID, setPayment, setPaymentADMIN } from './room.provider';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import './room.css';

function Room() {
  //Router params
  const {room_id} = useParams();
  //User
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(storedUser || null);
  //Room data
  const [roomData, setRoomData] = useState(null); 
  const [user_rankGifter, setUser_rankGifter] = useState([]);
  const [user_rankBuilder, setUser_rankBuilder] = useState([]);
  const [user_rankGuide, setUser_rankGuide] = useState([]);
  const [user_rankLegend, setUser_rankLegend] = useState([]);
  //Modal
  const [showModal, setShowModal] = useState(false);
  const [showModalUser, setShowModalUser] = useState();
  //Translation
  const { t } = useTranslation();
  const lang= localStorage.getItem('language')
  const [canAcceptUserPayments, setCanAcceptUserPayments] = useState(false);

  //Rooting
  const navigate = useNavigate();

  useEffect(() => {
      i18n.changeLanguage(lang); 
    }, [lang]);

  useEffect(() => {
    try {
      call_getRoomByID();
    } catch (error) {
      navigate("/boards");
    }
  }, []);


  async function call_getRoomByID() {
    try {
      const roomData = await getRoomByID(room_id);
      if(roomData.status===200){
        if(!roomData.data.users){
          navigate("/boards");
        }
        
        setRoomData(roomData.data);

        setUser_rankGifter(roomData.data.users.filter(x=> x.rank_name === "Gifter"));
        setUser_rankBuilder(roomData.data.users.filter(x=> x.rank_name === "Builder"));
        setUser_rankGuide(roomData.data.users.filter(x=> x.rank_name === "Guide"));
        setUser_rankLegend(roomData.data.users.filter(x=> x.rank_name === "Legend"));
        const userss = roomData.data.users;
        const utenteT = userss.find(utente => utente.username === user.username)
        if(utenteT && utenteT.rank_name === "Legend"){
          //Legend
          setCanAcceptUserPayments(true);
        } else {
          setCanAcceptUserPayments(false);
        }
      }
    } catch (error) {
      console.error('Error fetching room:', error);
    }
  }

  if (!roomData) {
    return <div>Loading...</div>; 
  }

  function getGifterClassname(user) {
    if(roomData.board_level >= 5 && user.payment_status == 't' && user.confirm_payment_status == 't'){
      return 'UserPlacement-userData gifter active';
    } else if(roomData.board_level >= 5 && user.payment_status == 't' && user.confirm_payment_status == 'f'){
      return 'UserPlacement-userData gifter active_half';
    } else if(user.payment_status == 't'){
      return 'UserPlacement-userData gifter active';
    }
    return 'UserPlacement-userData gifter';
  }

  function displayUserPlacement() {
    return (
      <div>
        <div className='UserPlacement-table-container'>
          <table className='UserPlacement-table'>
            <tbody>
              <tr key="0">
                <td data-attr="4-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[3]) : null} className={user_rankGifter[3] ? getGifterClassname(user_rankGifter[3]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[3]?.username}</span></div></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td data-attr="8-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[7]) : null} className={user_rankGifter[7] ? getGifterClassname(user_rankGifter[7]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[7]?.username}</span></div></td>
              </tr>
              <tr key="1">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr key="2">
                <td data-attr="3-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[2]) : null} className={user_rankGifter[2] ? getGifterClassname(user_rankGifter[2]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[2]?.username}</span></div></td>
                <td data-attr="10-Builder"><div onClick={() => JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankBuilder[1]) : null} className='UserPlacement-userData builder'><span>{user_rankBuilder[1]?.username}</span></div></td>
                <td></td>
                <td></td>
                <td></td>
                <td data-attr="12-Builder"><div onClick={() => JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankBuilder[3]) : null} className='UserPlacement-userData builder'><span>{user_rankBuilder[3]?.username}</span></div></td>
                <td data-attr="7-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[6]) : null} className={user_rankGifter[6] ? getGifterClassname(user_rankGifter[6]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[6]?.username}</span></div></td>
              </tr>
              <tr key="3">
                <td></td>
                <td></td>
                <td data-attr="13-Guide"><div onClick={() =>  JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGuide[0]) : null} className='UserPlacement-userData guide'><span>{user_rankGuide[0]?.username}</span></div></td>
                <td data-attr="15-Legend"><div onClick={() => openModal(user_rankLegend[0])} className='UserPlacement-userData legend'><span>{user_rankLegend[0]?.username}</span></div></td>
                <td data-attr="14-Guide"><div onClick={() =>  JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGuide[1]) : null} className='UserPlacement-userData guide'><span>{user_rankGuide[1]?.username}</span></div></td>
                <td></td>
                <td></td>
              </tr>
              <tr key="4">
                <td data-attr="2-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[1]) : null} className={user_rankGifter[1] ? getGifterClassname(user_rankGifter[1]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[1]?.username}</span></div></td>
                <td data-attr="9-Builder"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankBuilder[0]) : null} className='UserPlacement-userData builder'><span>{user_rankBuilder[0]?.username}</span></div></td>
                <td></td>
                <td></td>
                <td></td>
                <td data-attr="11-Builder"><div onClick={() =>  JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankBuilder[2]) : null} className='UserPlacement-userData builder'><span>{user_rankBuilder[2]?.username}</span></div></td>
                <td data-attr="6-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[5]) : null} className={user_rankGifter[5] ? getGifterClassname(user_rankGifter[5]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[5]?.username}</span></div></td>
              </tr>
              <tr key="5">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr key="6">
                <td data-attr="1-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[0]) : null} className={user_rankGifter[0] ? getGifterClassname(user_rankGifter[0]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[0]?.username}</span></div></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td data-attr="5-Gifter"><div onClick={() => canAcceptUserPayments || JSON.parse(localStorage.getItem("user")).isAdmin ? openModal(user_rankGifter[4]) : null} className={user_rankGifter[4] ? getGifterClassname(user_rankGifter[4]) : 'UserPlacement-userData empty'}><span>{user_rankGifter[4]?.username}</span></div></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='rm_footer'>
          <div>
            { roomData.board_level == 1 ? <p>{t('roomInfo1')}</p> : null }
            { roomData.board_level == 2 ? <p>{t('roomInfo2')}</p> : null }
            { roomData.board_level == 3 ? <p>{t('roomInfo3')}</p> : null }
            { roomData.board_level == 4 ? <p>{t('roomInfo4')}</p> : null }
            { roomData.board_level == 5 ? <p>{t('roomInfo5')}</p> : null }
            { roomData.board_level == 6 ? <p>{t('roomInfo6')}</p> : null }
            { roomData.board_level == 7 ? <p>{t('roomInfo7')}</p> : null }
          </div>
        </div>
      </div>
    );
  }

  async function confirmUserPaymentStatus(usr_id) {
    const resp = await setPayment(room_id, usr_id);
    if(resp.status===200){
      disposeModal();
      call_getRoomByID();
    }
  }

  async function confirmUserPaymentStatusADMIN(usr_id) {
    const resp = await setPaymentADMIN(room_id, usr_id);
    if(resp.status===200){
      disposeModal();
      call_getRoomByID();
    }
  }

  function openModal(user) {
    if(user){
      setShowModalUser(user);
      setShowModal(true);
    }
  }

  function disposeModal() {
    setShowModal(false);
  }

  function displayModal() {
    if (showModal) {
      return (
        <div className='modal-space'>
          <div className="modal-container">
            <div className="modal-header">
              <div>User: {showModalUser?.username}</div>
              <button variant="primary" onClick={disposeModal}>X</button>
            </div>
            <div className="modal-body">
              <div>
                {/* <div><b>Name:</b> {showModalUser?.name}</div>
                <div><b>Phone:</b> {showModalUser?.phone}</div> */}
                <div><b>Telegram:</b> {showModalUser?.telegram}</div>
                {/* <div><b>Email:</b> {showModalUser?.email}</div>
                <div><b>Wallet Address:</b> {showModalUser?.wallet_address}</div> */}
              </div>
            </div>
            {
              JSON.parse(localStorage.getItem("user")).isAdmin || canAcceptUserPayments ? 
              <div className='modal-footer'>
               {roomData.board_level >= 5 && showModalUser.payment_status == 't' && showModalUser.confirm_payment_status == 'f' && JSON.parse(localStorage.getItem("user")).isAdmin ? <button variant="primary" onClick={()=> confirmUserPaymentStatusADMIN(showModalUser?.id)}>Confirm Payment</button> : <button variant="primary" onClick={()=> confirmUserPaymentStatus(showModalUser?.id)}>{t('pay')}</button>}
            </div>
              : null
            }
            
          </div>
        </div>
      );
    } else {
      return '';
    }
  }

  return (
    <div>
      {displayUserPlacement()}
      {displayModal()}
    </div>      
  );
}

export default Room;
