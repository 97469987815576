import React, { useEffect, useState } from 'react';
import { getUsers, getUsersRoles, getRooms, setLockUser, addRoomsBot, checkUsernameExistence, getRoomsWithPaymentWait, getQueueList } from './admin_panel.provider';
import { useNavigate } from 'react-router-dom';
import i18n, { t } from 'i18next';
import './admin_panel.css';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowUpFromGroundWater, faBars, faDoorOpen, faLock, faLockOpen, faRobot } from '@fortawesome/free-solid-svg-icons';
import { faBots } from '@fortawesome/free-brands-svg-icons';

function AdminPanel() {
  const [users, setUsers] = useState(null);
  const [usersRoles, setUsersRoles] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [roomsWithPaymentWait, setRoomsWithPaymentWait] = useState(null);
  const [queueList, setQueueList] = useState(null);
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(storedUser || null);
  const [is_searchAllUsers_Active, set_searchAllUsers_Active] = useState(true);
  const [is_searchBlockedUsers_Active, set_searchBlockedUsers_Active] = useState(false);
  const [is_searchAdminUsers_Active, set_searchAdminUsers_Active] = useState(false);

  const [modalAddBOT_Active, setModalAddBOT_Active] = useState(false);
  const [botData, setBotData] = useState(null);
  const [botRoomID, setBotRoomID] = useState(0);
  const [botUsernameExistance, setBotUsernameExistance] = useState(true);

  const [input_searchUsers, setInput_searchUsers] = useState('');
  const [input_searchUsersRoles, setInput_searchUsersRoles] = useState('');
  const [input_searchRooms, setInput_searchRooms] = useState('');
  const [input_searchRoomsWithPaymentWait, setInput_searchRoomsWithPaymentWait] = useState('');
  const [input_searchQueueList, setInput_searchQueueList] = useState('');
  
  useEffect(() => {
    try{
      if (!(user.isAdmin)){
        navigate('/')
      }
    }catch(error){
      navigate('/')
    }
  },[])

  //Rooting
  const navigate = useNavigate();

  const [filters, setFilters] = useState({ 
    usersData : {
      query : 1,
      page: 0,
      results_per_page: 10,
      search: ''
    },
    usersRolesData : {
      page: 0,
      results_per_page: 10,
      search: ''
    },
    roomsData : {
      page: 0,
      results_per_page: 10,
      search: ''
    },
    roomsWithPaymentWaitData : {
      page: 0,
      results_per_page: 10,
      search: ''
    },
    queueListData : {
      page: 0,
      results_per_page: 10,
      search: ''
    }
  });
  
  async function call_getUsers() {
    try {
      setUsers(null);
      const response = await getUsers(filters.usersData.search, filters.usersData.query, filters.usersData.page, filters.usersData.results_per_page);
      setUsers(response.data);
    } catch (error) {
      //debugger;
      console.error('Error fetching users:', error);
    }
  }
  async function call_getUsersRoles() {
    try {
      setUsersRoles(null);
      const response = await getUsersRoles(filters.usersRolesData.search, filters.usersRolesData.page, filters.usersRolesData.results_per_page);
      setUsersRoles(response.data);
    } catch (error) {
      console.error('Error fetching usersRoles:', error);
    }
  }
  async function call_getRooms() {
    try {
      setRooms(null);
      const response = await getRooms(filters.roomsData.search, filters.roomsData.page, filters.roomsData.results_per_page);
      setRooms(response.data);
    } catch (error) {
      console.error('Error fetching Rooms:', error);
    }
  }

  async function call_getRoomsWithPaymentWait() {
    try {
      setRoomsWithPaymentWait(null);
      const response = await getRoomsWithPaymentWait(filters.roomsWithPaymentWaitData.search, filters.roomsWithPaymentWaitData.page, filters.roomsWithPaymentWaitData.results_per_page);
      setRoomsWithPaymentWait(response.data);
    } catch (error) {
      console.error('Error fetching RoomsWithPaymentWait:', error);
    }
  }

  async function call_getQueueList() {
    try {
      setQueueList(null);
      const response = await getQueueList(filters.queueListData.search, filters.queueListData.page, filters.queueListData.results_per_page);
      setQueueList(response.data);
    } catch (error) {
      console.error('Error fetching QueueList:', error);
    }
  }

  async function call_addBot() {
    try {
      const response = await addRoomsBot(botRoomID, botData);
      call_getRooms();
      alert("Operation done with success!")
    } catch (error) {
      console.error('Error fetching Rooms:', error);
    }
  }

  async function call_checkUsernameExistence() {
    try {
      const response = await checkUsernameExistence(botData.username);
      if(response?.data===true){
        alert(t('username.used'));
        setBotUsernameExistance(true);
      } else {
        setBotUsernameExistance(false);
      }
    } catch (error) {
      console.error('Error fetching Rooms:', error);
    }
  }

  useEffect(() => {
    call_getUsers();
    call_getUsersRoles();
    call_getRooms();
    call_getRoomsWithPaymentWait();
    call_getQueueList();
  }, []);

  function searchAllUsers(){
    if(!is_searchAllUsers_Active){
      filters.usersData.page = 0;
      filters.usersData.results_per_page = 10;
    }
    set_searchAllUsers_Active(true);
    set_searchBlockedUsers_Active(false);
    set_searchAdminUsers_Active(false);
    setUsers(null);
    filters.usersData.query = 1;
    setFilters(filters);
    call_getUsers();
  }
  function searchBlockedUsers(){
    if(!is_searchBlockedUsers_Active){
      filters.usersData.page = 0;
      filters.usersData.results_per_page = 10;
    }
    set_searchAllUsers_Active(false);
    set_searchBlockedUsers_Active(true);
    set_searchAdminUsers_Active(false);
    setUsers(null);
    filters.usersData.query = 2;
    setFilters(filters);
    call_getUsers();
  }
  function searchAdminUsers(){
    if(!is_searchAdminUsers_Active){
      filters.usersData.page = 0;
      filters.usersData.results_per_page = 10;
    }
    set_searchAllUsers_Active(false);
    set_searchBlockedUsers_Active(false);
    set_searchAdminUsers_Active(true);
    setUsers(null);
    filters.usersData.query = 3;
    setFilters(filters);
    call_getUsers();
  }

  function search_searchUsers(){
    filters.usersData.page = 0;
    filters.usersData.search = input_searchUsers?.trim() || '';
    setFilters(filters);
    if(is_searchAllUsers_Active){
      searchAllUsers();
    } else if(is_searchBlockedUsers_Active){
      searchBlockedUsers();
    } else if(is_searchAdminUsers_Active){
      is_searchAdminUsers_Active();
    }
  }

  function next_searchUsers(){
    if((users?.data.length + ((users?.current_page * users?.results_per_page))) < users?.total_results){
      filters.usersData.page = filters.usersData.page+1;
      setFilters(filters);
      if(is_searchAllUsers_Active){
        searchAllUsers();
      } else if(is_searchBlockedUsers_Active){
        searchBlockedUsers();
      } else if(is_searchAdminUsers_Active){
        is_searchAdminUsers_Active();
      }
    }
  }
  function prev_searchUsers(){
    if(filters.usersData.page > 0){
      filters.usersData.page = filters.usersData.page-1;
      setFilters(filters);
      if(is_searchAllUsers_Active){
        searchAllUsers();
      } else if(is_searchBlockedUsers_Active){
        searchBlockedUsers();
      } else if(is_searchAdminUsers_Active){
        is_searchAdminUsers_Active();
      }
    }
  }


  function search_searchUsersRoles(){
    filters.usersRolesData.page = 0;
    filters.usersRolesData.search = input_searchUsersRoles?.trim() || '';
    setFilters(filters);
    call_getUsersRoles();
  }
  function next_searchUsersRoles(){
    if((usersRoles?.data.length + ((usersRoles?.current_page * usersRoles?.results_per_page))) < usersRoles?.total_results){
      filters.usersRolesData.page = filters.usersRolesData.page+1
      setFilters(filters);
      call_getUsersRoles();
    }
  }
  function prev_searchUsersRoles(){
    if(filters.usersRolesData.page > 0){
      filters.usersRolesData.page = filters.usersRolesData.page-1;
      setFilters(filters);
      call_getUsersRoles();
    }
  }


  function search_searchRooms(){
    filters.roomsData.page = 0;
    filters.roomsData.search = input_searchRooms?.trim() || '';
    setFilters(filters);
    call_getRooms();
  }
  function next_searchRooms(){
    if((rooms?.data.length + ((rooms?.current_page * rooms?.results_per_page))) < rooms?.total_results){
      filters.roomsData.page = filters.roomsData.page+1
      setFilters(filters);
      call_getRooms();
    }
  }
  function prev_searchRooms(){
    if(filters.roomsData.page > 0){
      filters.roomsData.page = filters.roomsData.page-1;
      setFilters(filters);
      call_getRooms();
    }
  }

  
  function search_searchRoomsWithPaymentWait(){
      filters.roomsWithPaymentWaitData.page = 0;
      filters.roomsWithPaymentWaitData.search = input_searchRoomsWithPaymentWait?.trim() || '';
      setFilters(filters);
      call_getRoomsWithPaymentWait();
  }
  function next_searchRoomsWithPaymentWait(){
    if((roomsWithPaymentWait?.data.length + ((roomsWithPaymentWait?.current_page * roomsWithPaymentWait?.results_per_page))) < roomsWithPaymentWait?.total_results){
      filters.roomsWithPaymentWaitData.page = filters.roomsWithPaymentWaitData.page+1
      setFilters(filters);
      call_getRoomsWithPaymentWait();
    }
  }
  function prev_searchRoomsWithPaymentWait(){
    if(filters.roomsWithPaymentWaitData.page > 0){
      filters.roomsWithPaymentWaitData.page = filters.roomsWithPaymentWaitData.page-1;
      setFilters(filters);
      call_getRoomsWithPaymentWait();
    }
  }

  function search_searchQueueList(){
    filters.queueListData.page = 0;
    filters.queueListData.search = input_searchQueueList?.trim() || '';
    setFilters(filters);
    call_getQueueList();
  }
  function next_searchQueueList(){
    if((queueList?.data.length + ((queueList?.current_page * queueList?.results_per_page))) < queueList?.total_results){
      filters.queueListData.page = filters.queueListData.page+1
      setFilters(filters);
      call_getQueueList();
    }
  }
  function prev_searchQueueList(){
    if(filters.queueListData.page > 0){
      filters.queueListData.page = filters.queueListData.page-1;
      setFilters(filters);
      call_getQueueList();
    }
  }

  function getRankName(id) {
    switch (id) {
      case 1:
        return "Gifter";
      case 2:
        return "Builder";
      case 3:
        return "Guide";
      case 4:
        return "Legend";
      default:
        return "Undefined";
    }
  }

  //convert ms to date (gg/mm/aaaa)
  function convertDate(ms) {
    let date = new Date(ms);
    //if day is single digit, add 0 before
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  function renderQueueList() {
    return (
    <div className='box'>
      <div className='box-grid'>
        <table className='header_table'>
          <thead>
            <tr>
              <th className='text-center'>Id</th>
              <th>User</th>
              <th>Rank</th>
              <th>Board</th>
              <th>Queue Date</th>
            </tr>
            <tr key="searchbox" className='searchbox'>
              <th colSpan={5}>
                <div>
                  <input value={input_searchQueueList} onChange={(e) => setInput_searchQueueList(e.target.value)} type="text" />
                  <button onClick={search_searchQueueList}>Search</button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
          {!queueList ? <tr className='box-grid-item'><td className='text-center' colSpan={5}>Loading...</td></tr> : null}
            {Array.from({ length: queueList?.data.length }, (_, index) => (
                <tr key={index}>
                  <td className='text-center'>{queueList.data[index].user_id}</td>
                  <td>{queueList.data[index].username}</td>
                  <td>{getRankName(queueList.data[index].rank_id)}</td>
                  <td>{queueList.data[index].room_level}</td>
                  <td>{convertDate(queueList.data[index].queue_date)}</td>
                </tr>
              ))}
            {queueList && queueList?.data.length == 0 ? <tr className='box-grid-item'><td className='text-center' colSpan={5}>No results found</td></tr>: null }
          </tbody>
        </table>
        <div className='box-grid-item footer'>
        <div><label>Results <b>{queueList?.data.length > 0 ? ((queueList?.current_page * queueList?.results_per_page)+1) : 0} - {queueList?.data.length + ((queueList?.current_page * queueList?.results_per_page))}</b></label></div>
          <div><label className='actionbutton' onClick={prev_searchQueueList}>Previous</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label className='actionbutton' onClick={next_searchQueueList}>Next</label></div>
          <div><label>Total Results <b>{queueList?.total_results}</b></label></div>
        </div>
      </div>
    </div>
    );
  }

  function renderUsers() {
    return (
    <div className='box'>
      <div className='box-grid'>
        <table className='header_table'>
          <thead>
            <tr>
              <th className='text-center'>Id</th>
              <th>User</th>
              <th>Email</th>
              <th>Telegram</th>
              <th  className='text-center'>Action</th>
            </tr>
            <tr key="searchbox" className='searchbox'>
              <th colSpan={5}>
                <div>
                  <input value={input_searchUsers} onChange={(e) => setInput_searchUsers(e.target.value)} type="text" />
                  <button onClick={search_searchUsers}>Search</button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
          {!users ? <tr className='box-grid-item'><td className='text-center' colSpan={5}>Loading...</td></tr> : null}
            {Array.from({ length: users?.data.length }, (_, index) => (
                <tr key={index} className={ users?.data[index].isLocked ? 'box-grid-item grey' : ( users?.data[index].isAdmin ? 'box-grid-item gold' : 'box-grid-item')}>
                  <td className='text-center'>{users.data[index].id}</td>
                  <td>{users.data[index].username}</td>
                  <td>{users.data[index].email}</td>
                  <td>{users.data[index].telegram}</td>
                  <td className='text-center actionbutton'>
                    {users?.data[index].isLocked && users.data[index].username.includes("_deleted_") ? null : (<div title={users?.data[index].isLocked ? "Unlock user" : "Lock user"} onClick={() => call_setLockUser(users.data[index].id, !users.data[index].isLocked)}>{users?.data[index].isLocked ? <FontAwesomeIcon icon={faLockOpen}/> : <FontAwesomeIcon icon={faLock}/>}</div>)}
                  </td>
                </tr>
              ))}
            {users && users?.data.length == 0 ? <tr className='box-grid-item'><td className='text-center' colSpan={5}>No results found</td></tr>: null }
          </tbody>
        </table>
        <div className='box-grid-item footer'>
         <div><label>Results <b>{users?.data.length > 0 ? ((users?.current_page * users?.results_per_page)+1) : 0} - {users?.data.length + ((users?.current_page * users?.results_per_page))}</b></label></div>
          <div><label className='actionbutton' onClick={prev_searchUsers}>Previous</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label className='actionbutton' onClick={next_searchUsers}>Next</label></div>
          <div><label>Total Results <b>{users?.total_results}</b></label></div>
        </div>
      </div>
    </div>
    );
  }

  async function call_setRoomsLeader(room_id, user_id) {
    //await setRoomsLeader(room_id, user_id);
    call_getUsersRoles();
  }
  
  async function call_setLockUser(user_id, isLocked) {
    await setLockUser(user_id, isLocked);
    call_getUsers();
  }

  function renderUsersRoles() {
    return (
    <div className='box'>
      <div className='box-grid'>
        <table className='header_table'>
          <thead>
            <tr>
              <th className='text-center'>Role Id</th>
              <th>Room ID</th>
              <th>User</th>
              <th>Rank</th>
              <th>Payment</th>
              {<th  className='text-center'>Action</th>}
            </tr>
            <tr key="searchbox" className='searchbox'>
              <th colSpan={6}>
                <div>
                  <input value={input_searchUsersRoles} onChange={(e) => setInput_searchUsersRoles(e.target.value)} type="text" />
                  <button onClick={search_searchUsersRoles}>Search</button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!usersRoles ? <tr className='box-grid-item'><td className='text-center' colSpan={6}>Loading...</td></tr> : null}
            {Array.from({ length: usersRoles?.data.length }, (_, index) => (
                <tr key={index} className={ usersRoles?.data[index].isLocked ? 'box-grid-item grey' : ( usersRoles?.data[index].isAdmin ? 'box-grid-item gold' : 'box-grid-item')}>
                  <td className='text-center'>{usersRoles.data[index].id}</td>
                  <td>{usersRoles.data[index].room[0].room_id}</td>
                  <td>{usersRoles.data[index].username}</td>
                  <td>{usersRoles.data[index].rank_name}</td>
                  <td>{usersRoles.data[index].rank_name == 'Gifter' ? (usersRoles.data[index].payment_status == 't' ? <span className='success'>Done</span> : <span className='danger'>Not yet</span>) : "- - - -"}</td>
                  <td className='text-center actionbutton'><div title='Enter Room' onClick={() => EnterRoom(usersRoles.data[index].room[0].room_id)}><FontAwesomeIcon icon={faDoorOpen}/></div></td>
                </tr>
              ))}
            {usersRoles && usersRoles?.data.length == 0 ? <tr className='box-grid-item'><td className='text-center' colSpan={6}>No results found</td></tr>: null }
            
          </tbody>
        </table>
        <div className='box-grid-item footer'>
          <div><label>Results <b>{usersRoles?.data.length > 0 ? ((usersRoles?.current_page * users?.results_per_page)+1) : 0} - {usersRoles?.data.length + ((usersRoles?.current_page * usersRoles?.results_per_page))}</b></label></div>
          <div><label className='actionbutton' onClick={prev_searchUsersRoles}>Previous</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label className='actionbutton' onClick={next_searchUsersRoles}>Next</label></div>
          <div><label>Total Results <b>{usersRoles?.total_results}</b></label></div>
        </div>
      </div>
    </div>
    );
  }

  function renderRooms() {
    return (
    <div className='box'>
      <div className='box-grid'>
        <table className='header_table'>
          <thead>
            <tr>
              <th className='text-center'>Room ID</th>
              <th>Board Name</th>
              <th>Users Playing</th>
              <th>Total Users</th>
              <th>Last update</th>
              <th  className='text-center'>Action</th>
            </tr>
            <tr key="searchbox" className='searchbox'>
              <th colSpan={6}>
                <div>
                  <input value={input_searchRooms} onChange={(e) => setInput_searchRooms(e.target.value)} type="text" />
                  <button onClick={search_searchRooms}>Search</button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!rooms ? <tr className='box-grid-item'><td className='text-center' colSpan={6}>Loading...</td></tr> : null}
            {Array.from({ length: rooms?.data.length }, (_, index) => (
                <tr key={index} className='box-grid-item'>
                  <td className='text-center'>{rooms.data[index].room_id}</td>
                  <td>{rooms.data[index].board_name}</td>
                  <td>{getRoom_users_statisticsString( rooms.data[index].room_users_statistics)}</td>
                  <td>{rooms.data[index].total_users_count}/15</td>
                  <td>{new Date(rooms.data[index].last_room_update).toLocaleDateString()}</td>
                  <td className='text-center actionbutton'>
                    <div title='Add BOT' onClick={() => addBot(rooms.data[index].room_id)}><FontAwesomeIcon icon={faRobot}/></div>
                    <div title='Enter Room' onClick={() => EnterRoom(rooms.data[index].room_id)}><FontAwesomeIcon icon={faDoorOpen}/></div>
                  </td>
                </tr>
              ))}
            {rooms && rooms?.data.length == 0 ? <tr className='box-grid-item'><td className='text-center' colSpan={6}>No results found</td></tr>: null }
          </tbody>
        </table>
        <div className='box-grid-item footer'>
          <div><label>Results <b>{rooms?.data.length > 0 ? ((rooms?.current_page * users?.results_per_page)+1) : 0} - {rooms?.data.length + ((rooms?.current_page * rooms?.results_per_page))}</b></label></div>
          <div><label className='actionbutton' onClick={prev_searchRooms}>Previous</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label className='actionbutton' onClick={next_searchRooms}>Next</label></div>
          <div><label>Total Results <b>{rooms?.total_results}</b></label></div>
        </div>
      </div>
    </div>
    );
  }

  function EnterRoom(room_id) {
    navigate('/room/'+room_id);
  }

  function renderRoomsConfirmationPayment() {
    return (
    <div className='box'>
      <div className='box-grid'>
        <table className='header_table'>
          <thead>
            <tr>
              <th className='text-center'>Room ID</th>
              <th>Board Name</th>
              <th>Payment Confirmation</th>
              <th  className='text-center'>Action</th>
            </tr>
            <tr key="searchbox" className='searchbox'>
              <th colSpan={5}>
                <div>
                  <input value={input_searchRoomsWithPaymentWait} onChange={(e) => setInput_searchRoomsWithPaymentWait(e.target.value)} type="text" />
                  <button onClick={search_searchRoomsWithPaymentWait}>Search</button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!roomsWithPaymentWait ? <tr className='box-grid-item'><td className='text-center' colSpan={5}>Loading...</td></tr> : null}
            {Array.from({ length: roomsWithPaymentWait?.data.length }, (_, index) => (
                <tr key={index} className='box-grid-item'>
                  <td className='text-center'>{roomsWithPaymentWait.data[index].room_id}</td>
                  <td>{roomsWithPaymentWait.data[index].board_name}</td>
                  <td>Waiting</td>
                  <td className='text-center actionbutton'>
                    <div title='Enter Room' onClick={() => EnterRoom(roomsWithPaymentWait.data[index].room_id)}><FontAwesomeIcon icon={faDoorOpen}/></div>
                  </td>
                </tr>
              ))}
            {roomsWithPaymentWait && roomsWithPaymentWait?.data.length == 0 ? <tr className='box-grid-item'><td className='text-center' colSpan={5}>No results found</td></tr>: null }
          </tbody>
        </table>
        <div className='box-grid-item footer'>
          <div><label>Results <b>{roomsWithPaymentWait?.data.length > 0 ? ((roomsWithPaymentWait?.current_page * users?.results_per_page)+1) : 0} - {roomsWithPaymentWait?.data.length + ((roomsWithPaymentWait?.current_page * roomsWithPaymentWait?.results_per_page))}</b></label></div>
          <div><label className='actionbutton' onClick={prev_searchRoomsWithPaymentWait}>Previous</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label className='actionbutton' onClick={next_searchRoomsWithPaymentWait}>Next</label></div>
          <div><label>Total Results <b>{roomsWithPaymentWait?.total_results}</b></label></div>
        </div>
      </div>
    </div>
    );
  }

  function renderIconsLegend() {
    return (
    <div className='box'>
      <div className='box-grid'>
        <table className='header_table'>
          <thead>
            <tr>
              <th className='text-center'>Icon</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {/*<tr key="faArrowUpFromGroundWater">
              <td  className='text-center'><FontAwesomeIcon icon={faArrowUpFromGroundWater}/></td>
              <td>Make user Room Leader ( Use with accuracy )</td>
    </tr>*/}
            <tr key="faLock">
              <td  className='text-center'><FontAwesomeIcon icon={faLock}/></td>
              <td>Lock user account ( User will be kicked out from all rooms )</td>
            </tr>
            <tr key="faLockOpen">
              <td  className='text-center'><FontAwesomeIcon icon={faLockOpen}/></td>
              <td>Unlock user account ( User will be assignded as Gifter to first avaible room set in queue)</td>
            </tr>
            <tr key="faRobot">
              <td  className='text-center'><FontAwesomeIcon icon={faRobot}/></td>
              <td>Add BOT to selected room ( If the room is full for selected role, the bot will not be created )</td>
            </tr>
            <tr key="faDoorOpen">
              <td  className='text-center'><FontAwesomeIcon icon={faDoorOpen}/></td>
              <td>Enter the room</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    );
  }


  function getRoom_users_statisticsString(room_users_statistics) {
    let response = 'Empty';
    if (room_users_statistics) {
      response = '';
      room_users_statistics.forEach((userGroup) => {
        switch (userGroup.rank_name) {
          case 'Gifter':
            response += userGroup.rank_name + 's ' + userGroup.user_count + '/8 ';
            break;
          case 'Builder':
            response += userGroup.rank_name + 's ' + userGroup.user_count + '/4 ';
            break;
          case 'Guide':
            response += userGroup.rank_name + 's ' + userGroup.user_count + '/2 ';
            break;
          case 'Legend':
            response += userGroup.rank_name + ' ' + userGroup.user_count + '/1 ';
            break;
          default:
            response += userGroup.rank_name + ' ' + userGroup.user_count + '/? ';
            break;
        }
      });
    }
    return response;
  }

  function addBot(i_room_id) {
    if(i_room_id){
      setBotRoomID(i_room_id);
      setBotUsernameExistance(true);
    } else {
      setBotRoomID(0);
    }
    setModalAddBOT_Active(!modalAddBOT_Active);
  }

  function newBot() {
    if(botData?.username && !botUsernameExistance){
      setModalAddBOT_Active(!modalAddBOT_Active);
      if(botData?.doRoomCycle && botData?.doRoomCycle === "On"){
        let b = botData; b.doRoomCycle = true;
      } else {
        let b = botData; b.doRoomCycle = false;
      }
      if(!botData?.rank_name){
        let b = botData; b.rank_name = "Gifter";
        setBotData(b);
      }
      call_addBot();
    } else {
      alert("Check form inputs!");
    }
    
  }

  function check_checkUsernameExistence(){
    if(botData?.username){
      call_checkUsernameExistence(botData?.username);
    }
  }

  return (
    <div className='box-page'>
        {modalAddBOT_Active ? <div className='modal-space'>
          <div className='modal-container'>
            <div className="modal-header">
              <span>Add BOT</span>
              <button variant="primary" onClick={addBot}>X</button>
            </div>
            <div className="modal-body">
              <div>
              <div><b><label style={{display: 'block'}}>Rank*</label></b> 
              <select  onChange={(e) => {let b = botData || {}; b.rank_name = e.target.value; setBotData(b);}}>
                <option value="Gifter" key="Gifter" selected>Gifter</option>
                <option value="Builder" key="Builder">Builder</option>
                <option value="Guide" key="Guide">Guide</option>
                <option value="Legend" key="Legend">Legend</option>
              </select>
              </div>
              <div><b><label style={{display: 'block'}}>Username*</label></b> <input type="text" onBlur={check_checkUsernameExistence} onChange={(e) => {let b = botData || {}; b.username = e.target.value; setBotData(b);}} /></div>
              <div><b><label style={{display: 'block'}}>Phone</label></b> <input type="text" onChange={(e) => {let b = botData || {}; b.phone = e.target.value; setBotData(b);}} /></div>
              <div><b><label style={{display: 'block'}}>Telegram</label></b> <input type="text" onChange={(e) => {let b = botData || {}; b.telegram = e.target.value; setBotData(b);}} /></div>
              <div><b><label style={{display: 'block'}}>Wallet Address</label></b> <input type="text" onChange={(e) => {let b = botData || {}; b.wallet_address = e.target.value; setBotData(b);}} /></div>
              <div><b><label style={{display: 'block'}}>Cycled</label></b> <input type="checkbox" onChange={(e) => {let b = botData || {}; b.doRoomCycle = e.target.value; setBotData(b);}} /></div>
              </div>
            </div>
            <div className='modal-footer'>
              <button variant="primary" onClick={()=> newBot()}>Save</button>
            </div>
          </div>
        </div> : null}

      <div className='box-container'>
        <div className='box-container-panel-name-container'>
          <div className={is_searchAllUsers_Active ? 'box-container-panel-name active': 'box-container-panel-name'}  onClick={searchAllUsers}><span>All users</span></div>
          <div className={is_searchBlockedUsers_Active ? 'box-container-panel-name active': 'box-container-panel-name'}><span onClick={searchBlockedUsers}>Blocked users</span></div>
          <div className={is_searchAdminUsers_Active ? 'box-container-panel-name active': 'box-container-panel-name'}><span onClick={searchAdminUsers}>Admin users</span></div>
        </div>
        {renderUsers()}
      </div>
      <div className='box-container'>
        <div className='box-container-panel-name-container'>
          <div className='box-container-panel-name active'><span>User roles</span></div>
        </div>
        {renderUsersRoles()}
      </div>
      <div className='box-container'>
        <div className='box-container-panel-name-container'>
          <div className='box-container-panel-name active'><span>Room List</span></div>
        </div>
        {renderRooms()}
      </div>
      <div className='box-container'>
        <div className='box-container-panel-name-container'>
          <div className='box-container-panel-name active'><span>Payment Confirmation</span></div>
        </div>
        {renderRoomsConfirmationPayment()}
      </div>
      <div className='box-container'>
        <div className='box-container-panel-name-container'>
          <div className='box-container-panel-name active'><span>Query List</span></div>
        </div>
        {renderQueueList()}
      </div>
      <div className='box-container'>
        <div className='box-container-panel-name-container'>
          <div className='box-container-panel-name active'><span>Icons Description</span></div>
        </div>
        {renderIconsLegend()}
      </div>
    </div>
  );
}

export default AdminPanel;
