import React, { useState, useEffect } from 'react';
import { Loader } from '../loader';
import { getAllRooms, getAllQueueRooms } from './board_list.provider';
import { useNavigate } from 'react-router-dom';
import './board_list.css';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

function Boards() {
  const [board_list, setBoard_list] = useState([]);
  const [queue_list, setQueue_list] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang= localStorage.getItem('language')

  useEffect(() => {
      i18n.changeLanguage(lang); 
    }, [lang]);

  useEffect(() => {
    call_getAllRooms();
    call_getAllQueueRooms();
  }, []);

  async function call_getAllRooms(){
    const response = await getAllRooms();
    setBoard_list(response.data)
  }

  async function call_getAllQueueRooms(){
    const response = await getAllQueueRooms();
    setQueue_list(response.data);
  }
  
return (
  JSON.parse(localStorage.getItem("user")).isLedaer ? <div className="room-container">
    {Array.from({ length: board_list.length }, (_, index) => index + 1).map((tableNumber) => (
      <div
        key={tableNumber}
        className="room-card clickable"
        style={{
          backgroundColor: (() => {
            switch (board_list[tableNumber-1]?.board_level) {
              case 1:
                return '#ff0101';
              case 2:
                return '#e46425';
              case 3:
                return '#f2ca2b';
              case 4:
                return '#1aa70c';
              case 5:
                return '#3847db';
              case 6:
                return '#5b64bb';
              case 7:
                return '#7835cc';
              default:
                return 'white';
            }
          })(),
        }}
        onClick={()=>navigate(`/room/${board_list[tableNumber-1].room_id}`)}
      >
        <p>ID: {board_list[tableNumber-1].room_id}</p>
        <br/>
        {t('tavole')} {board_list[tableNumber-1]?.board_level } 
        </div>))}
  </div> :  
  <div className="room-container">
    {Array.from({ length: board_list.length }, (_, index) => index + 1).map((tableNumber) => (
      <div
        key={tableNumber}
        className="room-card clickable"
        style={{
          backgroundColor: (() => {
            switch (tableNumber) {
              case 1:
                return '#ff0101';
              case 2:
                return '#e46425';
              case 3:
                return '#f2ca2b';
              case 4:
                return '#1aa70c';
              case 5:
                return '#3847db';
              case 6:
                return '#5b64bb';
              case 7:
                return '#7835cc';
              default:
                return 'white';
            }
          })(),
        }}
        onClick={()=>navigate(`/room/${board_list[tableNumber-1].room_id}`)}
      >
        {t('tavole')} {tableNumber }
        </div>
))}
        {Array.from({ length: (7 - board_list.length) }, (_, index) => index + 1).map((tableNumber) => (
      <div key={tableNumber+board_list.length} className="room-card non-clickable">
        {t('tavole')} {tableNumber+board_list.length} {queue_list.find(x=>x.board_level===(tableNumber+board_list.length)) ? <div><br/>{t('queue')}</div> : null}
      </div>
))}
   
 </div>
);

}

export default Boards;
